import React from "react";
import { EmptyState, ProgressCircle, Button } from "@equiem/react-admin-ui";
import { useTranslation } from "@equiem/localisation-eq1";

import { StickyHeaderTable } from "../../components/StickyHeaderTable";
import { bookingDiscountsTableHeaders } from "./components/BookingDiscountsTable";
import { BookingDiscountRow } from "./components/BookingDiscountRow";
import { RiAddLine } from "@equiem/react-admin-ui/icons";
import { useGetSiteBookingDiscountsQuery } from "../../generated/gateway-client";
import { SettingsTab } from "../settings/components/SettingsTab";
import { BookingDiscountFormModal } from "./components/BookingDiscountFormModal";
import { withContexts } from "../../contexts/withContexts";
import {
  BookingDiscountAddEditContext,
  BookingDiscountAddEditProvider,
} from "./components/BookingDiscountAddEditProvider";

export const BookingDiscountsLocal: React.FC = () => {
  const { t } = useTranslation();
  const { data, error, loading } = useGetSiteBookingDiscountsQuery();
  const discounts = data?.siteBookingDiscountsList.edges.map((edge) => edge.node) ?? [];

  if (error != null) {
    return <div>{error.message}</div>;
  }

  return (
    <BookingDiscountAddEditProvider>
      <BookingDiscountAddEditContext.Consumer>
        {({ create }) => (
          <SettingsTab
            prefixTopMenu={
              <div className="w-100 text-right">
                <Button className="main-button" variant="primary" size="md" onClick={create}>
                  <RiAddLine size={16} />
                  {t("bookings.discounts.addNewDiscount")}
                </Button>
              </div>
            }
          >
            <>
              {loading && discounts.length === 0 && (
                <div className="mt-6 mb-6 d-flex justify-content-center align-items-center">
                  <ProgressCircle size="md" />
                  <span className="ml-4">{`${t("common.loading")}...`}</span>
                </div>
              )}
              {discounts.length > 0 && !loading && (
                <StickyHeaderTable loading={loading} rowHeaders={bookingDiscountsTableHeaders(t)}>
                  {discounts.map((discount, i) => (
                    <BookingDiscountRow key={i} discount={discount!} />
                  ))}
                </StickyHeaderTable>
              )}
              {discounts.length === 0 && !loading && (
                <div className="w-100 mt-6 mb-6">
                  <EmptyState message={t("bookings.discounts.emptyState")} />
                </div>
              )}
            </>
            <BookingDiscountFormModal />
          </SettingsTab>
        )}
      </BookingDiscountAddEditContext.Consumer>
    </BookingDiscountAddEditProvider>
  );
};

export const BookingDiscountsPage = withContexts(BookingDiscountsLocal);
