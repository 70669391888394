import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, useTheme, Form as EqForm, Button, Alert, remainingCharacters } from "@equiem/react-admin-ui";
import { BookingModal } from "../../operations/contexts/BookingModalContext";
import { useTranslation } from "@equiem/localisation-eq1";
import { Field, Formik, Form as FormikForm } from "formik";
import { BookingDiscountType, Weekday } from "../../../generated/gateway-client";
import { useSiteContext } from "@equiem/lib";
import { ResourceSelector } from "../../../components/ResourceSelector";
import { BookingDiscountAddEditContext, ViewMode } from "./BookingDiscountAddEditProvider";
import { RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import { formatWeekday } from "../../../lib/formatWeekday";
import { validateForm } from "../util/validateForm";
import { parseTime, toSeconds } from "../../../lib/dateTimeHelpers";

const maxTitleLength = 50;
const week = [Weekday.Mon, Weekday.Tue, Weekday.Wed, Weekday.Thu, Weekday.Fri, Weekday.Sat, Weekday.Sun];

export const BookingDiscountForm: React.FC = () => {
  const modal = useContext(BookingModal);
  const { t, i18n } = useTranslation();
  const { spacers, colors } = useTheme(true);
  const { timezone } = useSiteContext();
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const { createOrUpdate, value, viewMode } = useContext(BookingDiscountAddEditContext);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    modal.setTitle(
      viewMode === ViewMode.New ? t("bookings.discounts.addNewDiscount") : t("bookings.discounts.editDiscount"),
    );
    modal.setShowConfirmationBeforeClose(dirty);
    modal.setConfirmationTextBeforeClose(t("bookings.discounts.formCloseWarning"));
  }, [modal, t, viewMode, dirty]);

  const isErrorDisplayed = useCallback(
    (errorCheck: string | undefined) => (hasClickedSubmit ? errorCheck : undefined),
    [hasClickedSubmit],
  );

  const closeModal = useCallback(() => {
    modal.close();
  }, [modal]);

  return (
    <Formik
      initialValues={value}
      validate={validateForm(t, timezone)}
      onSubmit={createOrUpdate}
      innerRef={(formikActions) => setDirty(formikActions?.dirty ?? false)}
    >
      {({ isSubmitting, values, submitForm, setFieldValue, errors }) => (
        <>
          <Modal.Body>
            <FormikForm>
              <div>
                {viewMode === ViewMode.Edit && (
                  <Alert
                    size="large"
                    variant="gray"
                    icon={<RiErrorWarningLine size={18} color={colors.grayscale[60]} />}
                    message={
                      <span className="edit-discount-helptext">{t("bookings.discounts.editDiscountHelpText")}</span>
                    }
                    className="mb-5"
                  />
                )}
                <EqForm.Group>
                  <div className="availability-type-container">
                    <Field
                      label={t("bookings.discounts.periodic")}
                      className="availability-type-radio w-50"
                      component={EqForm.EnclosedRadioButton}
                      id={BookingDiscountType.Periodic}
                      name="type"
                      value={values.type === BookingDiscountType.Periodic}
                      description={t("bookings.discounts.periodicBookingHelpText")}
                      onClick={async () => {
                        await setFieldValue("type", BookingDiscountType.Periodic);
                      }}
                    ></Field>
                    <Field
                      label={t("bookings.discounts.earlyBooking")}
                      className="availability-type-radio w-50"
                      component={EqForm.EnclosedRadioButton}
                      id={BookingDiscountType.EarlyBooking}
                      value={values.type === BookingDiscountType.EarlyBooking}
                      name="type"
                      description={t("bookings.discounts.earlyBookingHelpText")}
                      onClick={async () => {
                        await setFieldValue("type", BookingDiscountType.EarlyBooking);
                      }}
                    ></Field>
                  </div>
                </EqForm.Group>
                <EqForm.Group
                  label={t("bookings.discounts.titleField")}
                  required
                  error={isErrorDisplayed(errors.title)}
                  hint={t("common.remainingCharacters", {
                    count: remainingCharacters(maxTitleLength, values.title.length),
                  })}
                  tooltipText={t("bookings.discounts.titleFieldHelpText")}
                  showTooltip={true}
                >
                  <Field
                    id="title"
                    name="title"
                    placeholder={t("bookings.discounts.titleFieldPlaceholder")}
                    as={EqForm.Input}
                    maxLength={maxTitleLength}
                  />
                </EqForm.Group>
                <div className="form-group-container">
                  <EqForm.Group
                    required
                    error={isErrorDisplayed(errors.startDate)}
                    label={t("bookings.resources.startDate")}
                  >
                    <Field id="startDate" name="startDate" as={EqForm.Input} type="date" max="9999-12-31" />
                  </EqForm.Group>
                  <EqForm.Group
                    required
                    error={isErrorDisplayed(errors.endDate)}
                    label={t("bookings.resources.endDate")}
                  >
                    <Field id="endDate" name="endDate" as={EqForm.Input} type="date" max="9999-12-31" />
                  </EqForm.Group>
                </div>

                {values.type === BookingDiscountType.Periodic && (
                  <>
                    <div className="form-group-container">
                      <EqForm.Group
                        error={isErrorDisplayed(errors.availability?.startTime)}
                        label={t("bookings.settings.startTime")}
                      >
                        <Field
                          as={EqForm.TimeSelect}
                          setFieldValue={setFieldValue}
                          name="availability.startTime"
                          className="start-time"
                          defaultOptionValue={t("bookings.settings.startTime")}
                          startTime={{ hour: 0, minute: 0 }}
                          endTime={{ hour: 23, minute: 59 }}
                          value={toSeconds(values.availability.startTime) ?? 0}
                          diffMinutes={15}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("availability.startTime", parseTime(e)).catch(console.error);
                          }}
                        />
                      </EqForm.Group>
                      <EqForm.Group
                        error={isErrorDisplayed(errors.availability?.endTime)}
                        label={t("bookings.settings.endTime")}
                      >
                        <Field
                          as={EqForm.TimeSelect}
                          setFieldValue={setFieldValue}
                          name="availability.endTime"
                          className="end-time"
                          defaultOptionValue={t("bookings.settings.endTime")}
                          startTime={{ hour: 0, minute: 0 }}
                          endTime={{ hour: 23, minute: 59 }}
                          value={toSeconds(values.availability.endTime) ?? 0}
                          diffMinutes={15}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("availability.endTime", parseTime(e)).catch(console.error);
                          }}
                        />
                      </EqForm.Group>
                    </div>

                    <EqForm.Group
                      error={isErrorDisplayed(errors.availability?.days)}
                      label={t("bookings.operations.availabilityDays")}
                      required
                    >
                      <div className="availability-days">
                        {week.map((weekDay) => (
                          <Field
                            id={weekDay}
                            key={weekDay}
                            type="button"
                            name="availability.availability.days"
                            disabled={isSubmitting}
                            label={formatWeekday(weekDay, i18n.language, "narrow")}
                            as={EqForm.ToggleButton}
                            value={values.availability.days?.includes(weekDay) ?? false}
                            onChange={(state: boolean) => {
                              const updatedWeekDays = state
                                ? [...(values.availability.days ?? []), weekDay]
                                : (values.availability.days ?? []).filter((day) => day !== weekDay);
                              setFieldValue(
                                "availability.days",
                                week.filter((wd) => updatedWeekDays.includes(wd)),
                              ).catch(console.error);
                            }}
                          />
                        ))}
                      </div>
                    </EqForm.Group>
                  </>
                )}

                {values.type === BookingDiscountType.EarlyBooking && (
                  <EqForm.Group
                    required
                    error={isErrorDisplayed(errors.availability?.daysBeforeBookingToApply)}
                    label={t("bookings.discounts.numberOfDaysField")}
                  >
                    <Field
                      id="availability.daysBeforeBookingToApply"
                      name="availability.daysBeforeBookingToApply"
                      placeholder={t("bookings.discounts.numberOfDaysFieldPlaceholder")}
                      as={EqForm.Input}
                      min={1}
                      max={365}
                      type="number"
                    />
                  </EqForm.Group>
                )}

                <EqForm.Group
                  error={isErrorDisplayed(errors.percentage)}
                  required
                  label={t("bookings.discounts.percentageField")}
                >
                  <Field
                    id="percentage"
                    name="percentage"
                    placeholder={t("bookings.discounts.percentageFieldPlaceholder")}
                    as={EqForm.Input}
                    min={1}
                    max={100}
                    type="number"
                  />
                </EqForm.Group>
                <div className="resource-picker">
                  <ResourceSelector
                    fieldName="resources"
                    showErrorCheck={isErrorDisplayed(errors.resources?.[0]) != null}
                  />
                </div>
              </div>
            </FormikForm>
          </Modal.Body>
          <Modal.Footer>
            <div className="discount-buttons w-100">
              <div className="buttons-group">
                <Button className="w-100" variant="outline" onClick={closeModal}>
                  {t("common.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-100"
                  onClick={() => {
                    setHasClickedSubmit(true);
                    submitForm().catch((e) => {
                      console.error(e);
                    });
                  }}
                >
                  {t("common.save")}
                </Button>
              </div>
            </div>
          </Modal.Footer>
          <style jsx>{`
            .availability-days :global(button) {
              flex-grow: 1;
            }
            .availability-days,
            .availability-type-container {
              display: flex;
              gap: ${spacers.s3};
            }
            .availability-type-radio {
              flex: 1;
            }
            .availability-type-radio :global(label) {
              width: 100%;
              height: 100%;
            }
            .availability-type-container :global(h1) {
              text-overflow: ellipsis;
              text-wrap: wrap;
            }
            .availability-type-container :global(p) {
              text-wrap: wrap;
            }
            .discount-buttons :global(button) {
              width: 40%;
            }
            .discount-buttons .buttons-group {
              display: flex;
              gap: 8px;
            }
            .form-group-container {
              display: flex;
              gap: ${spacers.s5};
            }
            .resource-picker :global(.tags-container span) {
              font-size: 12px;
            }
            .resource-picker :global(.tags-container .placeholder-text) {
              font-size: 14px;
            }
            .edit-discount-helptext {
              color: ${colors.grayscale[60]};
            }
          `}</style>
        </>
      )}
    </Formik>
  );
};
